import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Form from '../molecules/Form';

const Wrapper = styled.div`
  padding: 0 15px;
  margin: 100px auto;
  display: flex;
  flex-direction: column-reverse;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const LeftColumn = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 40%;
  }
`;

const RightColumn = styled.div`
  margin-bottom: 100px;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 50%;
    margin-bottom: 0;
  }
`;

const Contact = () => {
  const imageData = useStaticQuery(query);

  return (
    <Wrapper>
      <LeftColumn>
        <GatsbyImage
          image={imageData.file.childImageSharp.gatsbyImageData}
          alt='About Me'
        />
      </LeftColumn>
      <RightColumn>
        <Form />
      </RightColumn>
    </Wrapper>
  );
};

const query = graphql`
  query ContactImage {
    file(name: { eq: "aboutme" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: NONE
          formats: WEBP
        )
      }
    }
  }
`;

export default Contact;
