import React, { useState } from 'react';
import styled from 'styled-components';
import Headline from '../atoms/Headline';
import Text from '../atoms/Text';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'gatsby';

const Container = styled.div``;

const StyledHeadline = styled(Headline)``;

const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .input-label {
      position: relative;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .required-info {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        color: red;
        font-family: ${({ theme }) => theme.fontFamily};
        font-size: ${({ theme }) => theme.size16};
      }

      /* @media (min-width: 992px) {
        max-width: 500px;
      } */
    }
  }

  form input {
    background-color: #f8f8f8;
    border: none;
    border-bottom: 2px solid #dfdfdf;
    outline: none;
    height: 50px;
    width: 100%;
    padding: 10px;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size14};

    @media (min-width: ${({ theme }) => theme.desktop}) {
      font-size: ${({ theme }) => theme.size15};
    }

    /* @media (min-width: 992px) {
      max-width: 500px;
    } */
  }

  .submit-btn {
    margin-top: 50px;
    width: 180px;
    height: 50px;
    border: none;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.size18};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.regular};
    align-self: center;
    &:hover {
      background-color: #232a3e;
    }
  }
`;

const SubmitSuccessfull = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 650px;
  padding: 15px 30px;
  text-align: center;
  height: 200px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  z-index: 999;
  transform: translate(-50%, -10%);
`;

const SubmitText = styled(Text)`
  color: ${({ theme }) => theme.white};
`;

const SubmitButton = styled.button`
  margin-top: 30px;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.size18};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.regular};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.white};
  }
`;

const Agreement = styled.div`
  position: relative;
  display: flex;
  input {
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px !important;
    height: 15px !important;
  }

  label {
    padding-left: 25px;
    max-width: 300px;
    font-size: 12px;
  }

  .required-info {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size16};
  }

  a {
    margin-left: 2px;
  }
`;

const Form = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleCloseButton = () => {
    setFormSubmitted(false);
    setFormError(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, cartItems) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post('https://formsubmit.co/ajax/e810423d7c832016206418a518560311', {
        Name: data.Name,
        Phone: data.Phone,
        Email: data.Email,
      })
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
          setFormError(false);
        } else {
          setFormSubmitted(false);
          setFormError(true);
        }
      })
      .catch((error) => {
        setFormSubmitted(false);
        setFormError(true);
      });
  };
  return (
    <Container>
      {formSubmitted && (
        <SubmitSuccessfull>
          <SubmitText>Pomyślnie wysłano wiadomość</SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}

      {formError && (
        <SubmitSuccessfull>
          <SubmitText>
            Niestety wystąpił błąd! Prosimy o wysłanie wiadomości bezpośrednio
            na nasz adres email.
          </SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}
      <StyledHeadline>Napisz do mnie</StyledHeadline>
      {/* <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
        pellentesque, augue vitae aliquam imperdiet, tellus purus fermentum leo,
        at elementum leo erat quis enim. Nullam iaculis bibendum purus a
        facilisis.
      </Text> */}
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className='input-label'>
            <input
              placeholder='Imię i nazwisko*'
              {...register('Name', { required: true })}
            />
            {errors.Name && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            <input
              placeholder='Numer telefonu'
              {...register('Phone', { required: true })}
            />
            {errors.Phone && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            <input
              placeholder='Email*'
              {...register('Email', { required: true })}
            />
            {errors.Email && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>

          <Agreement>
            <label for='agreement'>
              Wypełnienie formularza oznacza, że podane w nim dane osobowe będą
              przetwarzane w celu przesłania oferty oraz kontaktu w jej sprawie.
              Zasady przetwarzania i informacje o administratorze znajdziesz
              <Link to='/polityka-prywatnosci-i-cookies'>tutaj.</Link>
              <input
                type='checkbox'
                id='agreement'
                name='agreement'
                {...register('Agreement', { required: true })}
              />
            </label>
            {errors.Agreement && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </Agreement>

          <button type='submit' className='submit-btn'>
            Wyślij
          </button>
        </form>
      </FormContainer>
    </Container>
  );
};

export default Form;
