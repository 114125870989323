import React from "react";
import MainTemplate from "../templates/MainTemplate";
import Contact from "../components/organisms/Contact";
import SubpageHeader from "../components/organisms/SubpageHeader";

const ContactPage = () => {
  return (
    <MainTemplate isMainPage={false}>
      <SubpageHeader headline="Kontakt" />
      <Contact />
    </MainTemplate>
  );
};

export default ContactPage;
